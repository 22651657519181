<template>
  <div class="dashboard">
    <div class="row gy-3 mb-3">
      <div class="col-md-12">
        <h6 class="mb-3">Hi, {{activeUser.first_name}}</h6>
        <div v-if="result.hospital" class="card bg-primary text-white">
          <div class="card-body text-center">
            <h5 class="text-white">Welcome to the Pharmacist's dashboard at {{result.hospital.name}}</h5>
              <p class="mb-0 small"><strong>Location:</strong> <span class="text-uppercase">
                <span v-if="result.hospital.ward" class="mb-0">
                  {{result.hospital.ward.name}}
                </span>
                <span v-if="result.hospital.lga">,  
                  {{result.hospital.lga.name}} LGA
                </span>
                <span v-if="result.hospital.lga.senatorial_district">,  
                  {{result.hospital.lga.senatorial_district.name}} senatorial District.
                </span>
              </span>
              </p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card text-center">
          <div class="card-body position-relative">
            <h5 class="text-primary"> <i class="ri ri-file-text-line align-bottom"></i> Medical History</h5>
            <p> Retrieve a Patient's Medical History</p>
            <router-link class="text-decoration-underline" to="/medical-histories"> Find Record </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card text-center">
          <div class="card-body position-relative">
            <h5 class="text-primary"> <i class="mdi mdi-plus-minus-box align-bottom"></i> Stock Summary</h5>
            <p> Check Medication Stock </p>
            <router-link class="text-decoration-underline" to="/medicine-stock"> View Summary </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card text-center">
          <div class="card-body position-relative">
            <h5 class="text-primary"> <i class="ri  ri-account-circle-line align-bottom"></i> Your Account</h5>
            <p>Information & setttings</p>
            <router-link class="text-decoration-underline" to="/account"> Account Settings </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import StatisticApexCount from '@/components/widgets/StatisticApexCount.vue';

export default {
    name: 'dashboard',
    components:{
      StatisticApexCount
    },
    computed:{
      activeUser(){
        return this.$store.state.AppActiveUser
      },
      result(){
        return this.$store.state.dashboardData
      }
    }
}
</script>


<style>

</style>